import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TermAndConditionsComponent } from './term-and-conditions.component'
import { QuillModule } from 'ngx-quill'
import { DataService } from './data.service'
import { RouterModule } from '@angular/router'
import { AdminSharedModule } from '../admin-shared/admin-shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// import { EditorModule } from '@tinymce/tinymce-angular';


@NgModule({
    imports: [
        AdminSharedModule,
        ReactiveFormsModule,
        // EditorModule,
        FormsModule,
        QuillModule.forRoot(),
        RouterModule.forChild([
            {
                path: '',
                component: TermAndConditionsComponent
            }
        ])
    ],
    declarations: [TermAndConditionsComponent],
    providers: [DataService]

})
export class TermAndConditionsModule {
    // Code Here
}
