import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { DataService } from './data.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
// import { EditorComponent } from '@tinymce/tinymce-angular'
@Component({
    selector: 'app-term-and-conditions',
    templateUrl: './term-and-conditions.component.html',
    styleUrls: ['./term-and-conditions.component.css']
})
export class TermAndConditionsComponent implements OnInit {
    dataStatus = false
    dataToSend: any = {
        id: null,
        content: ''
    }
    breadCrum = [
        {
            link: '/admin/term-and-conditions',
            value: 'Terms & Conditions'
        }
    ]
    // editorConfig = {
    //     toolbar: [
    //         ['bold', 'italic', 'underline', 'strike'], // Basic formatting
    //         [{ list: 'ordered' }, { list: 'bullet' }], // Lists
    //         [{ indent: '-1' }, { indent: '+1' }], // Indentation
    //         [{ align: [] }], // Text alignment
    //         ['link'] // Links
    //     ]
    // }
    editorConfig: any = ConstantsService.EDITOR_CONFIG
    saveLoading = false
    constructor(private ds: DataService, private alert: IAlertService) {}

    ngOnInit() {
        this.ds.getContent().subscribe((resp: any) => {
            if (resp.success === true) {
                if (resp.data !== null) {
                    this.dataToSend.id = resp.data.id
                    this.dataToSend.content = resp.data.content
                }
                this.dataStatus = true
            }
        })
    }

    saveContent() {
        this.saveLoading = true
        let saveUpdate =
            this.dataToSend.id != null
                ? this.ds.updateContent(this.dataToSend)
                : this.ds.addContent(this.dataToSend)
        saveUpdate.subscribe((resp: any) => {
            this.saveLoading = false
            if (resp.success === true) {
                if (this.dataToSend.id != null) {
                    this.alert.success('Content Updated Successfully')
                } else {
                    this.alert.success('Content saved successfully')
                    this.dataToSend.id = resp.data.id
                }
            } else {
                this.alert.error(resp.message)
            }
        })
    }
}
