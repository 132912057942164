<div class="main-container">
    <h1 class="title">Term & Conditions</h1>
    <app-breadcrums [data]="breadCrum"></app-breadcrums>
    <div class="text-end">
        <button class="cg primary sm" (click)="saveContent()" [disabled]="saveLoading" [ngClass]="saveLoading ? 'in-progress' : ''">Save <span></span><span></span><span></span></button>
    </div>
    <div class="box">
        <div class="content" *ngIf="dataStatus; else loader">
            <quill-editor [(ngModel)]="dataToSend.content" [modules]="editorConfig"></quill-editor>
            <!-- <editor #myEditor [(ngModel)]="dataToSend.content" apiKey="lyrukt9vhdgok660uqdt3u97ohx17l9ngc95amb5vyrs7nag"  [init]="{plugins: 'table', toolbar:'table', height: '100em'}"></editor> -->
        </div>
        <ng-template #loader>
            <div class="text-center"><img src="/assets/images/rolling-main.svg" alt="" /></div>
        </ng-template>
    </div>
</div>
